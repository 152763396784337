import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../context/ThemeContext';
import Pavan from '../assets/images/bg-surbhi.png';

const Header = () => {
  const { toggleTheme } = useContext(ThemeContext);

  return (
    <nav className="navbar navbar-expand-lg bg-transparent">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img src={Pavan} alt="Pavan" className="logo" width="50" height="50" />
        </a>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link" to="/login">Login</Link>
            </li>
            <li className="nav-item">
              <button className="btn" onClick={toggleTheme} aria-label="Toggle Theme">
                <i className="bi bi-brightness-high"></i>
              </button>
            </li>
          </ul>
          <form className="d-flex" role="search">
            <div className="input-group">
              <span className="input-group-text" id="search-icon">
                <i className="bi bi-search"></i>
              </span>
              <input
                type="search"
                className="form-control"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-icon"
              />
            </div>
          </form>
        </div>
      </div>
    </nav>
  );
};

export default Header;
