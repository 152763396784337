import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import Login from './components/Login';
import Signup from './components/Signup';
import AuthProvider, { AuthContext } from './context/AuthContext';
import ThemeProvider from './context/ThemeContext';
import './App.css';
import video from './assets/images/videoplayback.webm';
import { FaTimes } from 'react-icons/fa'; // Import the cancel icon

const App = () => {
  const [showVideo, setShowVideo] = useState(true);

  // This effect handles the video visibility and redirection to content
  useEffect(() => {
    if (!showVideo) {
      const timer = setTimeout(() => {
        setShowVideo(false);
      }, 1000); // Give some time for the video to disappear
      return () => clearTimeout(timer);
    }
  }, [showVideo]);

  const handleCancelClick = () => {
    setShowVideo(false); // Hide the video and show the content
  };

  return (
    <AuthProvider>
      <ThemeProvider>
        {showVideo ? (
          <div className="video-container">
            <video
              className="video-background"
              autoPlay
              muted
              playsInline
              onEnded={() => setShowVideo(false)}
              style={{ width: '100vw', height: '100vh', objectFit: 'cover' }} // Fullscreen style
            >
              <source src={video} type="video/webm" />
              Your browser does not support the video tag.
            </video>
            <button className="cancel-button" onClick={handleCancelClick}>
              <FaTimes size={30} color="white" />
            </button>
          </div>
        ) : (
          <Router>
            <div className="header"><Header /></div>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/private" element={<PrivateRoute><Home /></PrivateRoute>} />
            </Routes>
          </Router>
        )}
      </ThemeProvider>
    </AuthProvider>
  );
};

const PrivateRoute = ({ children }) => {
  const { auth } = React.useContext(AuthContext);
  return auth ? children : <Navigate to="/login" />;
};

export default App;
