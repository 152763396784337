import React from 'react';
import './Home.css';


const Home = () => {
  return (
    <div className="home-container">

      {/* Overlay Text */}
      <div className="overlay-text">
        <h1>Welcome to Pavan's Website</h1>
        <p>Dummy Website for Test by Pavan Ponnella</p>
      </div>
    </div>
  );
};

export default Home;
