import React from 'react';
import { toast } from 'react-toastify';
import Img from '../assets/images/bg-doctor.png';

const SignUp = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    toast.success('Sign Up successful!');
  };

  return (
    <section className="p-2">
      <div className="container">
        <div className="row g-0 ">
          <div className="row g-0">
            <div className="col-6">
              <img 
                className="img-fluid rounded-start w-100 h-100 object-fit-cover" 
                loading="lazy" 
                src={Img} 
                alt="Sign Up" 
              />
            </div>
            <div className="col-6">
              <div className="card-body p-2">
                <h2 className="h5">Sign Up</h2>
                <p className="text-secondary mb-2">Enter your details to register</p>
                <form onSubmit={handleSubmit}>
                  <div className="mb-2">
                    <label htmlFor="firstName" className="form-label">Name <span className="text-danger">*</span></label>
                    <input 
                      type="text" 
                      className="form-control form-control-sm" 
                      name="firstName" 
                      id="firstName" 
                      placeholder="First Name" 
                      required 
                    />
                  </div>
                  <div className="mb-2">
                    <label htmlFor="email" className="form-label">Email <span className="text-danger">*</span></label>
                    <input 
                      type="email" 
                      className="form-control form-control-sm" 
                      name="email" 
                      id="email" 
                      placeholder="name@example.com" 
                      required 
                    />
                  </div>
                  <div className="mb-2">
                    <label htmlFor="password" className="form-label">Password <span className="text-danger">*</span></label>
                    <input 
                      type="password" 
                      className="form-control form-control-sm" 
                      name="password" 
                      id="password" 
                      required 
                    />
                  </div>
                  <div className="form-check mb-2">
                    <input 
                      className="form-check-input" 
                      type="checkbox" 
                      id="iAgree" 
                      required 
                    />
                    <label className="form-check-label text-secondary" htmlFor="iAgree">
                      I agree to the <a href="#!" className="link-primary">terms and conditions</a>
                    </label>
                  </div>
                  <button className="btn btn-primary btn-sm w-100" type="submit">Sign Up</button>
                </form>
                <p className="text-secondary text-center mt-2 mb-0">
                  Already have an account? <a href="/Login" className="link-primary">Sign in</a>
                </p>
                <p className="mt-2">Or sign up with</p>
                <div className="d-flex gap-2">
                  <a href="#!" className="btn btn-outline-primary btn-sm">Google</a>
                  <a href="#!" className="btn btn-outline-primary btn-sm">Facebook</a>
                  <a href="#!" className="btn btn-outline-primary btn-sm">Twitter</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignUp;
