import React from 'react';
import { toast } from 'react-toastify';
import Img from '../assets/images/bg-doctor.png';

const SignIn = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    toast.success('Sign In successful!');
  };

  return (
    <section className="p-3 p-md-4 mt-3">
      <div className="container">
        <div className="row g-0">
          <div className="col-12 col-md-5">
            <img 
              className="img-fluid rounded-start w-100 h-100 object-fit-cover" 
              loading="lazy" 
              src={Img} 
              alt="Sign In" 
              style={{ maxHeight: '300px', backgroundColor: 'transparent' }} // Restrict image height
            />
          </div>
          <div className="col-12 col-md-7">
            <div className="card-body p-3">
              <h2 className="h4">Sign In</h2>
              <h3 className="fs-6 text-secondary mb-3">Enter your details to sign in</h3>
              <form onSubmit={handleSubmit}>
                <div className="mb-2">
                  <label htmlFor="email" className="form-label">Email <span className="text-danger">*</span></label>
                  <input 
                    type="email" 
                    className="form-control" 
                    name="email" 
                    id="email" 
                    placeholder="name@example.com" 
                    required 
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="password" className="form-label">Password <span className="text-danger">*</span></label>
                  <input 
                    type="password" 
                    className="form-control" 
                    name="password" 
                    id="password" 
                    required 
                  />
                </div>
                <div className="d-grid mb-3">
                  <button className="btn btn-primary" type="submit">
                    Sign In
                  </button>
                </div>
              </form>
              <p className="text-secondary text-center mb-2">
                Don't have an account? <a href="/signup" className="link-primary text-decoration-none">Sign up</a>
              </p>
              <p className="mb-1 text-center">Or sign in with</p>
              <div className="d-flex justify-content-center gap-2">
                <a href="#!" className="btn btn-outline-primary btn-sm">
                  <i className="bi bi-google"></i> Google
                </a>
                <a href="#!" className="btn btn-outline-primary btn-sm">
                  <i className="bi bi-facebook"></i> Facebook
                </a>
                <a href="#!" className="btn btn-outline-primary btn-sm">
                  <i className="bi bi-twitter"></i> Twitter
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignIn;
